import React from 'react'
import Nav from '../Nav/Nav'
import Slider from "../Slider/Slider"
import Clothing from '../Clothing/Clothing'
import Footer from '../Footer/Footer'
import "./HomePage.css"

function Home() {
  return (
    <div className='Home'>
      <Nav />
      <Slider />
      <Clothing />
      <Footer />

    </div>
  )
}

export default Home





















// import React from 'react';
// import Slider from "./Slider/Slider"
// // import imageSlider from './Components/Data'
// import "./App.css"

// function App() {
//   return (
//     <div className="App">
//       <Slider />
//       <h1>STYLERAPY</h1>
      
//     </div>
//   );
// }

// export default App;
